@font-face {
  font-family: 'NotoSansCJKKR';
  src: url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
  unicode-range: 'U+1100–11FF';
}

@font-face {
  font-family: 'Montserrat';
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
}
@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css);

button,
input,
li,
table,
a,
h1,
h2,
h3,
h4,
h5,
span,
p,
div,
table,
tr,
td,
th,
head,
select,
article {
  font-family: 'Montserrat', 'NotoSansCJKKR';
  letter-spacing: -0.8px;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 374px) {
  html,
  body,
  #root {
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  display: flex;
  justify-content: center;
  min-width: 275px;
  max-width: 556px;
}

#root {
  width: 100%;
  position: relative;
}

#appLayout {
  transition: all 0.2s;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

* {
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 769px) {
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
}

.MuiPickersTimePickerToolbar-hourMinuteLabel {
  align-items: center !important;
}

svg.icon {
  width: 1rem;
}

.MuiPickersToolbar-toolbar.MuiPickerDTToolbar-toolbar
  .MuiPickersToolbarText-toolbarTxt.MuiTypography-h3 {
  font-size: 2.125rem;
}

.MuiPickersToolbar-toolbar.MuiPickerDTToolbar-toolbar .MuiGrid-grid-xs-6 {
  max-width: 40%;
  flex-basis: 40%;
}

.MuiPickersToolbar-toolbar.MuiPickerDTToolbar-toolbar .MuiGrid-grid-xs-1 {
  max-width: 18.333333%;
  flex-basis: 18.333333%;
}

.MuiPickersToolbar-toolbar.MuiPickerDTToolbar-toolbar
  .MuiGrid-grid-xs-1
  > button {
  height: 32px;
}

input[type='password'] {
  font-family: 'Noto Sans KR' !important;
  letter-spacing: 2px;
}

.disable-hover * {
  pointer-events: none !important;
}

.NO_YEAR_DIALOG
  .MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters
  > button:first-child {
  display: none;
}

button {
  box-shadow: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
